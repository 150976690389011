import React from 'react';
import styles from './Shimmer.module.css';

function Shimmer(){
    return (
    <div className={styles.shimmer_wrapper}>
      <div className={styles.shimmer}></div>
    </div>
    )
}

export default Shimmer;