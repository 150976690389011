function helloWorld(){

    console.log(`                                                                       
%c  ooooooo                          o888  oooo        
%co888   888o oooo  oooo   ooooooo    888   888  ooooo 
%c888     888  888   888   ooooo888   888   888o888    
%c888o  8o888  888   888 888    888   888   8888 88o   
%c  88ooo88     888o88 8o 88ooo88 8o o888o o888o o888o 
%c       88o8                                                                                         
    `,"color:#FB5600", "color:#F7961B", "color:#FFA700", "color:#ffa711", "color:#FFBA00", "color:#FFFFFF");
                                                            
}

export default helloWorld;